<template>
  <div class="flex mb-2">
    <span class="mr-2 align-self-center">
      <BaseIcon icon="pi pi-check-circle" v-if="value" color="gc-color-success" />
      <BaseIcon
        :icon="error ? 'pi pi-exclamation-circle' : 'pi pi pi-circle-off'"
        v-else
        :color="error ? 'gc-color-danger' : 'text-color-secondary'"
      />
    </span>
    <BaseText class="align-self-center" :style="textStyle">
      {{ $t(text) }}
    </BaseText>
  </div>
</template>

<script>
export default {
  name: "PolicyChecker",

  props: {
    value: Boolean,
    text: String,
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    textStyle() {
      const style = {
        color: "var(--text-color-secondary)",
      };
      if (this.error) {
        style.color = "var(--gc-color-danger)";
      }

      if (this.value) {
        style.color = "var(--gc-color-success)";
      }

      return style;
    },
  },
};
</script>
