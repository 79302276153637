<template>
  <div class="flex justify-content-center my-8">
    <BaseCard class="card--fill-space w-12 card--register">
      <template #title>
        <BaseRow :no-gutter="true" class="justify-content-between">
          <BaseCol cols="11" class="flex align-items-center mb-3">
            <BaseHeadline size="6" no-margin class="text-primary">
              {{ $t("stp.register.title") }}
            </BaseHeadline>
          </BaseCol>
          <BaseCol class="flex flex-grow-0 align-items-center align-content-end mb-3">
            <BaseButton
              iconLeft="pi pi-times"
              rounded
              text
              color="gc-color-danger"
              @click="goToStartPage"
            />
          </BaseCol>
        </BaseRow>
      </template>

      <BaseRow :no-gutter="true">
        <BaseCol cols="12">
          <BaseMessage v-if="showSuccessMessage" severity="success" :closable="false">
            {{ $t("stp.register.message.success") }}
          </BaseMessage>

          <RegisterForm
            v-else
            @register="callApi($event)"
            :loading="isLoading"
            :backendValidation="backendValidation"
            :invitationToken="invitationToken"
          />
        </BaseCol>
      </BaseRow>
      <BaseRow :no-gutter="true" class="justify-content-center">
        <BaseCol cols="6" class="text-center">
          <BaseDivider />
          <BaseText size="xs">{{ $t("stp.register.label.login") }}</BaseText>
          <BaseText size="xs">
            <BaseLink :to="{ name: 'Login' }">{{ $t("stp.register.link.login") }}</BaseLink>
          </BaseText>
        </BaseCol>
      </BaseRow>
    </BaseCard>
  </div>
</template>

<script>
import { ref } from "vue";
import RegisterForm from "./partials/RegisterForm.vue";
import useApi from "@use/useApi";
import useNotifications from "@use/useNotifications";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "Register",

  components: {
    RegisterForm,
  },

  setup() {
    // Setup notification
    const { showSuccess, showError } = useNotifications();
    const showSuccessMessage = ref(false);

    // Setup store
    const store = useStore();

    const router = useRouter();
    const route = useRoute();

    const invitationToken = route.query.token;

    // Setup API method
    const { callApi, isLoading, docs, backendValidation } = useApi({
      module: "uc",
      method: "register",
      onSuccess: async () => {
        showSuccess("stp.register.notify.success.summary", "stp.register.notify.success.detail");

        if (invitationToken) {
          //Login directly when token available
          await store.dispatch("usm/auth/saveToken", {
            accessToken: docs.value.token.access_token,
            refreshToken: docs.value.token.refresh_token,
          });
          await router.push("/");
        } else {
          //Show success message, don't login
          showSuccessMessage.value = true;
        }
      },
      onError: () => showError(backendValidation.value.message, backendValidation.value.message),
    });

    const goToStartPage = async () => {
      try {
        await router.push({ name: "StartPage" });
      } catch {
        return;
      }
    };

    return {
      isLoading,
      callApi,
      backendValidation,
      showSuccessMessage,
      goToStartPage,
      invitationToken,
    };
  },
};
</script>

<style lang="scss" scoped>
.card--register {
  max-width: 40rem;
}
</style>
