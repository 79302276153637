<template>
  <BaseRow>
    <BaseCol :cols="colNumber">
      <BaseTextField
        type="password"
        label="shared.formElements.createPassword.label.password"
        v-model="passwordValue"
        :floatingLabel="floatingLabels"
        :validation="passwordValidation"
        :messages="passwordMessages"
        :error="passwordError"
        :maxLength="null"
      />
    </BaseCol>

    <BaseCol :cols="colNumber">
      <BaseTextField
        type="password"
        label="shared.formElements.createPassword.label.passwordConfirm"
        v-model="passwordConfirmValue"
        :floatingLabel="floatingLabels"
        :validation="passwordConfirmValidation"
        :messages="passwordConfirmMessages"
        :error="passwordConfirmError"
        :maxLength="null"
      />
    </BaseCol>

    <BaseCol cols="12" :class="{ 'flex flex-wrap': horizontalHints }">
      <PolicyChecker
        :class="{ 'mr-3': horizontalHints }"
        :error="error"
        :value="passwordLength"
        text="shared.formElements.policyChecker.length"
      />
      <PolicyChecker
        :class="{ 'mr-3': horizontalHints }"
        :error="error"
        :value="passwordLetters"
        text="shared.formElements.policyChecker.letters"
      />
      <PolicyChecker
        :class="{ 'mr-3': horizontalHints }"
        :error="error"
        :value="passwordNumbers"
        text="shared.formElements.policyChecker.numbers"
      />
      <PolicyChecker
        :class="{ 'mr-3': horizontalHints }"
        :error="error"
        :value="passwordSymbols"
        text="shared.formElements.policyChecker.symbols"
      />
      <PolicyChecker
        :class="{ 'mr-3': horizontalHints }"
        :error="error"
        :value="passwordsEqual"
        text="shared.formElements.policyChecker.confirm"
      />
    </BaseCol>
  </BaseRow>
</template>

<script>
import regex from "@data/regex";
import PolicyChecker from "./partials/PolicyChecker.vue";

export default {
  name: "PasswordCreate",

  components: {
    PolicyChecker,
  },

  props: {
    password: {
      type: String,
      required: false,
    },

    passwordConfirm: {
      type: String,
      required: false,
    },

    error: {
      type: Boolean,
      required: false,
      default: false,
    },

    floatingLabels: {
      type: Boolean,
      required: false,
      default: false,
    },

    sideBySide: {
      type: Boolean,
      required: false,
      default: false,
    },

    cols: {
      type: String,
      required: false,
      default: "",
    },

    horizontalHints: {
      type: Boolean,
      required: false,
      default: false,
    },

    passwordValidation: {
      type: Object,
      required: null,
    },

    passwordMessages: {
      type: Array,
      required: false,
      default: () => [],
    },

    passwordError: {
      type: Boolean,
      required: false,
      default: false,
    },

    passwordConfirmValidation: {
      type: Object,
      required: false,
    },

    passwordConfirmMessages: {
      type: Array,
      required: false,
      default: () => [],
    },

    passwordConfirmError: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    passwordLength() {
      if (!this.passwordValue) return false;

      return regex.passwordLength.test(this.passwordValue);
    },

    passwordLetters() {
      if (!this.passwordValue) return false;

      return regex.passwordLetters.test(this.passwordValue);
    },

    passwordNumbers() {
      if (!this.passwordValue) return false;

      return regex.passwordNumbers.test(this.passwordValue);
    },

    passwordSymbols() {
      if (!this.passwordValue) return false;

      return regex.passwordSymbols.test(this.passwordValue);
    },

    passwordsEqual() {
      if (!this.passwordValue || !this.passwordConfirmValue) return false;

      return this.passwordValue === this.passwordConfirmValue;
    },

    passwordPolicy() {
      if (!this.passwordValue) return false;

      return regex.passwordPolicy.test(this.passwordValue);
    },

    passwordComplete() {
      return this.passwordPolicy && this.passwordsEqual;
    },

    passwordValue: {
      get() {
        return this.password;
      },
      set(value) {
        this.$emit("update:password", value);
      },
    },

    passwordConfirmValue: {
      get() {
        return this.passwordConfirm;
      },
      set(value) {
        this.$emit("update:passwordConfirm", value);
      },
    },

    colNumber() {
      if (this.cols.length) {
        return this.cols;
      }

      return this.sideBySide ? "6" : "12";
    },
  },

  watch: {
    passwordComplete() {
      this.$emit("complete", this.passwordComplete);
    },
  },
};
</script>
