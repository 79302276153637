<template>
  <BaseForm
    @submit.prevent="register"
    :validation="v$"
    :message="bvMessage"
    :error="bvHasErrors"
    hideMandatory
  >
    <BaseRow>
      <BaseCol cols="6">
        <BaseTextField
          v-model="formValues.salutation"
          label="stp.register.form.label.salutation"
          placeholder="stp.register.form.placeholder.salutation"
          :validation="v$.salutation"
          :messages="bvShowErrors('salutation').messages"
          :error="bvShowErrors('salutation').hasMessages"
          :maxLength="maxLength.USERS.SALUTATION"
          floatingLabel
        />
      </BaseCol>
    </BaseRow>
    <BaseRow>
      <BaseCol cols="6">
        <BaseTextField
          v-model="formValues.firstName"
          label="stp.register.form.label.firstName"
          placeholder="stp.register.form.placeholder.firstName"
          :validation="v$.firstName"
          :messages="bvShowErrors('firstName').messages"
          :error="bvShowErrors('firstName').hasMessages"
          :maxLength="maxLength.USERS.FIRST_NAME"
          floatingLabel
        />
      </BaseCol>
      <BaseCol cols="6">
        <BaseTextField
          v-model="formValues.lastName"
          label="stp.register.form.label.lastName"
          placeholder="stp.register.form.placeholder.lastName"
          :validation="v$.lastName"
          :messages="bvShowErrors('lastName').messages"
          :error="bvShowErrors('lastName').hasMessages"
          :maxLength="maxLength.USERS.LAST_NAME"
          floatingLabel
        />
      </BaseCol>
    </BaseRow>
    <BaseRow>
      <BaseCol cols="6">
        <BaseTextField
          v-model="formValues.email"
          label="stp.register.form.label.email"
          placeholder="stp.register.form.placeholder.email"
          :validation="v$.email"
          :messages="bvShowErrors('email').messages"
          :error="bvShowErrors('email').hasMessages"
          :maxLength="maxLength.USERS.EMAIL"
          floatingLabel
        />
      </BaseCol>
    </BaseRow>
    <PasswordCreate
      v-model:password="formValues.password"
      v-model:passwordConfirm="formValues.passwordConfirmation"
      :passwordValidation="v$.password"
      :passwordMessages="bvShowErrors('password').messages"
      :passwordError="bvShowErrors('password').hasMessages"
      :passwordConfirmValidation="v$.passwordConfirmation"
      :passwordConfirmMessages="bvShowErrors('passwordConfirmation').messages"
      :passwordConfirmError="bvShowErrors('passwordConfirmation').hasMessages"
      :floatingLabels="true"
      :horizontalHints="true"
      cols="6"
      @complete="completePassword($event)"
    />

    <template #submit>
      <BaseRow class="justify-content-center mt-4">
        <BaseCol cols="6" class="text-center">
          <BaseButton
            type="submit"
            label="stp.register.form.label.btn"
            fullWidth
            :loading="loading"
          />
        </BaseCol>
      </BaseRow>
    </template>
  </BaseForm>
</template>

<script>
import useFormValidation from "@use/useFormValidation";
import useBackendValidation from "@use/useBackendValidation";
import { reactive, ref, watch } from "vue";
import PasswordCreate from "@shared/FormElements/PasswordCreate.vue";
import nullifyObject from "../../../../utilities-utls/helper/nullifyObject";
import maxLength from "@/modules/haw-components-hac/constants/maxLength";

export default {
  name: "RegisterForm",

  components: {
    PasswordCreate,
  },

  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    backendValidation: {
      type: Object,
      required: false,
      default: () => {},
    },

    invitationToken: {
      type: String,
      required: false,
    },
  },

  setup(props, { emit }) {
    // Initialize form values
    const form = reactive({
      invitationToken: props.invitationToken,
      email: null,
      salutation: null,
      title: null,
      firstName: null,
      lastName: null,
      password: null,
      passwordConfirmation: null,
    });

    // Setup validation
    const rules = {
      invitationToken: [],
      email: ["required", "email"],
      salutation: ["required"],
      title: [],
      firstName: ["required"],
      lastName: ["required"],
      password: ["required"],
      passwordConfirmation: ["required"],
    };

    const { v$, formValues } = useFormValidation({ formValues: form, rules });

    const isComplete = ref(false);
    const completePassword = (event) => {
      isComplete.value = event;
    };

    // Emit form values
    const register = () => {
      v$.value.$touch();
      if (v$.value.$error || !isComplete.value || props.loading) return;
      emit("register", nullifyObject(formValues));
    };

    const { updateValidation, bvMessage, bvHasErrors, bvShowErrors } = useBackendValidation();

    watch(props, () => {
      if (props.backendValidation) {
        updateValidation(props.backendValidation);
      }
    });

    return {
      v$,
      formValues,
      register,
      bvMessage,
      bvHasErrors,
      bvShowErrors,
      completePassword,
      maxLength,
    };
  },
};
</script>
